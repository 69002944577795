<template>
  <v-app>
    <v-app-bar
        app
        flat
        :prominent="$vuetify.breakpoint.smAndUp"
        :shrink-on-scroll="$vuetify.breakpoint.smAndUp"
        color="white"
    >
      <v-app-bar-nav-icon
          v-show="$vuetify.breakpoint.smAndDown"
          @click="drawer = !drawer"
      />
      <v-card
          v-show="!$vuetify.breakpoint.smAndDown"
          class="mx-auto"
          tile
          flat
      >
        <v-btn
            v-for="link in links"
            :key="link.title"
            :to="link.to"
            class="mx-auto"
            tile
            text
        >
          {{ link.title }}
        </v-btn>
      </v-card>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        v-show="$vuetify.breakpoint.smAndDown"
        app
    >
      <v-list>
        <v-list-item
            v-for="link in links"
            :key="link.to"
        >
          <v-btn
              :key="link.title"
              :to="link.to"
              class="float-left"
              text
          >
            {{ link.title }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main
    >
      <v-card
          class="ma-auto mt-lg-8 px-1"
          :width="main_width"
          :max-width="main_max_width"
          flat
          tile
      >
      <router-view/>
      </v-card>
    </v-main>
  </v-app>
</template>


<style>
#app {

}
</style>

<script>
import '@mdi/font/css/materialdesignicons.css'

export default {
  data: () => ({
    links: [
      {title: 'Velkommen', to: '/'},
      {title: 'Galleri', to: '/Gallery'},
      {title: 'Om mig', to: '/About'},
      {title: 'Kontakt', to: '/Contact'},
      // {name: '3DGallery', to: '/3DGallery'},
    ],
    drawer: false,
    main_max_width: "1000px",
    icons: {
      iconfont: 'mdi'
    }
  }),
  computed: {
    main_width() {
      if (this.$vuetify.breakpoint.smAndUp){
        return "80%"
      }
      return "100%"
    }
  }
}
</script>