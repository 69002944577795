<template>
  <div>
    <v-card
        flat
        tile
        class="text-center"
    >
      <h2>Billedhugger</h2>
      <h1>Steffen Lund</h1>
    </v-card>
    <v-card
        class="d-flex justify-center" flat tile
    >
      <v-card>
        <v-img
            width="100%"
            class="ma-0 pa-0"
            :src="welcome_image"
        />
      </v-card>
    </v-card>
  </div>

</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      welcome_image: require('../assets/front_page_image.png')
    }
  }
}
</script>

<style>

</style>
