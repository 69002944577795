import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// import colors from "vuetify/lib/util/colors"

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#3f51b5'
                // primary: colors.purple,
                // secondary: colors.gray.dark,
                // accent: colors.shades.black,
                // error: colors.red.accent3
            }
        }
    }
}

export default new Vuetify(opts)
